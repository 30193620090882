export const getFormattedDevicesList = (list: MediaDeviceInfo[]): MediaDeviceInfo[] => {
    const filteredList = list.filter(({ kind, deviceId }) => kind === "videoinput" && !!deviceId);
    const duplicatePrefixes = findDuplicatePrefixes(filteredList.map(({ label }) => label));
    return filteredList.map(item => {
        const duplicatePrefix = duplicatePrefixes.find(prefix => item.label.startsWith(prefix + ' '));

        return ({
            ...item,
            label: duplicatePrefix ? item.label.replace(duplicatePrefix, '') : item.label,
            deviceId: item.deviceId,
            kind: item.kind
        })
    })
        .sort((a, b) => a.label.localeCompare(b.label));
}

const findDuplicatePrefixes = (records) => {
    if (records.length <= 1) {
        return []
    }

    const [first, ...rest] = records
    const [prefix, _] = first.split(' ')

    const match = rest.find(r => r.startsWith(prefix + ' '))
    if (match) {
        return [prefix]
            .concat(findDuplicatePrefixes(rest.filter(r => !r.startsWith(prefix + ' '))))
    }

    return findDuplicatePrefixes(rest)
}