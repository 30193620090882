import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from 'src/services/userApi';
import { UserPreference } from 'src/types/user';

export const useFetchUserPreferences = () => {
    const { data, isLoading } = useGetUserSettingsQuery();
    const [updateUserPreferences] = useUpdateUserSettingsMutation();

    const handleUpdateUserPreferences = async (updatedPreferences: Partial<UserPreference>) => {
        try {
            await updateUserPreferences({
                ...data,
                ...updatedPreferences,
            }).unwrap();
        } catch (error) {
            console.error(error);
        }
    };

    return {
        loading: isLoading,
        ...data,
        onUpdate: handleUpdateUserPreferences,
    };
};
