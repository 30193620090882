import { j as d } from "./jsx-runtime-B6kdoens.js";
import { I as l } from "./Icon-GJXsxHcP.js";
const s = {
  DEFAULT: "ClientFolder",
  EMPTY: "FolderEmpty",
  ASSET: "FolderAsset",
  BUSINESS: "FolderCopy",
  PROJECT: "FolderSettings",
  COMPANY: "FolderCompany",
  TRUST: "FolderTrust",
  INDIVIDUAL: "FolderIndividual"
}, T = ({
  type: o = "DEFAULT",
  width: e = 24,
  height: r = 20,
  strokeWidth: t = 1.5,
  className: F = ""
}) => /* @__PURE__ */ d.jsx(
  l,
  {
    name: s[o],
    width: e,
    height: r,
    className: F,
    strokeWidth: t
  }
);
export {
  T as F
};
