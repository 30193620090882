import { LoanViewType } from "src/backend";
import { KeyStorage } from "src/constants/key-storage";
import { useGetKeyPreferenceQuery, useSetKeyPreferenceMutation } from "src/services/userApi";

import { useActiveLoan } from "../use-active-loan";


// gets and set the user loan view preference
export const useUserLoanViewType = () => {
    const { loan } = useActiveLoan();
    const { data = 'CONSOLIDATED_LENDER' as LoanViewType, isLoading } = useGetKeyPreferenceQuery<{ data: LoanViewType | undefined, isLoading: boolean }>({
        key: KeyStorage.LoanViewType
    });

    const [setKeyPreference] = useSetKeyPreferenceMutation();

    const onSetUserLoanView = (loanViewType: LoanViewType) => {
        setKeyPreference({
            key: KeyStorage.LoanViewType,
            value: loanViewType
        })
    }
    let loanViewType: LoanViewType = data;

    if (data !== 'CONSOLIDATED_LENDER' &&
        loan?.template?.loanViewType &&
        data !== loan?.template?.loanViewType) {
        loanViewType = loan?.template?.loanViewType;
    }

    return {
        onSetUserLoanView,
        // if user set the view type to consolidated lender, then return the view type, otherwise return null
        // backend returns the other view type by default if no view type is set
        loanViewType,
        loading: isLoading,
    } as const;
};