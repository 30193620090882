import { j as t } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as C, useState as u, useEffect as h } from "react";
import { B as x } from "./Button-BrPm3sL-.js";
import { I as y } from "./Icon-GJXsxHcP.js";
import { T as o } from "./Tooltip-Dy7rqQaX.js";
import { c as b } from "./utils-CJ9afRe1.js";
function g(r) {
  return async () => {
    if (!(navigator != null && navigator.clipboard))
      return console.warn("Clipboard not supported"), !1;
    try {
      return await navigator.clipboard.writeText(r), !0;
    } catch (a) {
      return console.warn("Copy failed", a), !1;
    }
  };
}
const B = C((r, n) => {
  const [a, i] = u(!1), [s, c] = u(!1), { text: d, ...p } = r, f = g(d);
  let e = 0;
  const m = (l) => {
    l.stopPropagation(), l.preventDefault(), f(), c(!0), i(!0), clearTimeout(e), e = setTimeout(() => {
      i(!1), c(!1);
    }, 2e3);
  };
  return h(() => () => clearTimeout(e), [e]), /* @__PURE__ */ t.jsx(o.Provider, { children: /* @__PURE__ */ t.jsxs(
    o,
    {
      disableHoverableContent: !0,
      open: a,
      onOpenChange: i,
      children: [
        /* @__PURE__ */ t.jsx(o.Trigger, { asChild: !0, children: /* @__PURE__ */ t.jsx(
          x,
          {
            ...p,
            type: "button",
            ref: n,
            "data-ui": s ? "active" : "",
            className: b("active:bg-gray-200 data-active:text-green-flowkit", p.className),
            variant: "text",
            onClick: m,
            children: /* @__PURE__ */ t.jsx(y, { name: s ? "Check" : "Copy", width: 20, height: 20, strokeWidth: 2 })
          }
        ) }),
        /* @__PURE__ */ t.jsx(o.Content, { children: s ? "Copied" : "Copy" })
      ]
    }
  ) });
});
export {
  B as C
};
