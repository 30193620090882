import * as React from "react"

export const TwoArrowsUpIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="m16.752 10.688-5.563-5.563-.781-.781c-.25-.25-.656-.25-.875 0L3.97 9.906l-.78.781c-.563.563.312 1.47.874.876L9.627 6l.343-.344a307891.823 307891.823 0 0 0 5.907 5.907c.562.562 1.437-.313.875-.876Z"
        />
        <path
            fill="currentColor"
            d="m11.158 9.438-.75-.75c-.25-.25-.656-.25-.875 0l-5.281 5.28-.75.75c-.563.563.312 1.47.875.876l5.28-5.281L9.97 10l4.813 4.813.75.75c.562.562 1.469-.313.875-.876l-5.25-5.25Z"
        />
    </svg>
)
