import Breadcrumb from "components/dist/atoms/Breadcrumb";
import Text from "components/dist/atoms/Text"
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";
import { classNames } from "src/utils/tailwind-class-names";

export const CopyMoveToDialogBreadcrumbItem = (props: { isActive: boolean, element: FormElementV2ResponseDtoExtended, onClick: (element: FormElementV2ResponseDtoExtended) => void }) => {
    return (<Breadcrumb.Item
        onClick={() => props.onClick(props.element)}
        key={props.element.id}
    >
        <Text
            className={classNames("py-3 cursor-pointer text-gray-neutral-70", {
                "text-black-primary": props.isActive,
            })}
            size="sm"
            truncate>{props.element.title}</Text>
    </Breadcrumb.Item>)
}