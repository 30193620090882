import * as React from "react"

export const OneArrowUpIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            fill="currentColor"
            d="M16.498 11.594 11.154 6.25l-.75-.75c-.25-.25-.656-.25-.875 0l-5.313 5.313-.75.75c-.562.562.313 1.468.875.874 1.782-1.78 3.532-3.53 5.313-5.312l.312-.313 4.907 4.907.75.75c.562.562 1.437-.313.875-.875Z"
        />
    </svg>
)
