import * as s from "react";
function u(e) {
  const [c, a] = s.useState(matchMedia(e).matches);
  return s.useEffect(() => {
    function n(r) {
      a(r.matches);
    }
    const t = matchMedia(e);
    return t.addEventListener("change", n), a(t.matches), () => t.removeEventListener("change", n);
  }, [e]), c;
}
export {
  u
};
