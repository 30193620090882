import Button from "components/dist/atoms/Button"
import Text from "components/dist/atoms/Text"

interface UndoToastProps {
    onUndo: () => void
    message: string
}
export const UndoToast = (props: UndoToastProps) => {

    return (<div className="flex flex-col gap-2 justify-start">
        <Text size="sm" as="div">
            {props.message}
        </Text>
        <div>
            <Button
                aria-label="Undo"
                size="sm"
                onClick={props.onUndo}
                variant="custom"
                className="underline px-0 py-0">
                Undo
            </Button>
        </div>
    </div>)
}