import * as React from "react"

export const OneArrowDownIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            fill="currentColor"
            d="M15.155 6.494c-1.594 1.594-3.156 3.187-4.75 4.781l-.25.25-4.344-4.344-.687-.687c-.563-.563-1.469.312-.875.875L9.03 12.15l.688.688c.25.25.656.25.874 0 1.594-1.594 3.188-3.157 4.782-4.75.218-.22.468-.47.687-.688.563-.562-.312-1.469-.906-.906Z"
        />
    </svg>
)
