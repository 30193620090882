// function that displays a toast of an action on no of elements while other infected elements were skipped

import { FormElementV2ResponseDtoExtended } from "src/types/formelement";

// eg ({{n}} items)|(item title) {{action taken}} and ({{n}} items)or(item title) ignored because {{item is infected or virus scan is incomplete}}
export const getElementActionsToastMessage = (cleanElements: FormElementV2ResponseDtoExtended[], notCleanElements: FormElementV2ResponseDtoExtended[], action: string): string => {
    const cleanElementsCount = cleanElements.length;
    const notCleanElementsCount = notCleanElements.length;
    const cleanElementsName = cleanElementsCount === 1 ? cleanElements[0].title : `${cleanElementsCount} items`;
    const notCleanElementsName = notCleanElementsCount === 1 ? notCleanElements[0].title : `${notCleanElementsCount} items`;
    const infectedElementsCount = notCleanElements.filter((element) => element.answer?.document?.virusScanState === 'INFECTED').length;
    const notScannedElementsCount = notCleanElementsCount - infectedElementsCount;
    // if we only have infected elements, we should show the infected message
    // if we only have not scanned elements, we should show the not scanned message
    // if we have both, we should show the combination with or
    let infectedMessage = `${notCleanElementsCount > 1 ? 'they are' : 'it is'} infected or virus scan is incomplete`;
    if (infectedElementsCount === 0) {
        infectedMessage = 'virus scan is incomplete'
    } else if (notScannedElementsCount === 0) {
        infectedMessage = `${notScannedElementsCount > 1 ? 'they are' : 'it is'} infected`
    }
    const notCleanElementsMessage = notCleanElementsCount > 0 ? `and ${notCleanElementsName} ignored because ${infectedMessage}` : '';
    return `${cleanElementsName} ${action} ${notCleanElementsMessage}`;
};