import { j as o } from "./jsx-runtime-B6kdoens.js";
import { c as r } from "./utils-CJ9afRe1.js";
const m = ({
  className: s,
  ...e
}) => /* @__PURE__ */ o.jsx(
  "div",
  {
    className: r(
      "animate-pulse rounded-md bg-black-20",
      s
    ),
    ...e
  }
);
export {
  m as S
};
