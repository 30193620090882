import { Role } from "src/backend";
import { UserType } from "src/constants/user";

import { useActiveLoan } from "./use-active-loan";

export const useUserType = (id: string) => {

    const { loan } = useActiveLoan();
    if (loan?.loanRoles.some(role => role.user?.id === id && ['BORROWER', 'LEAD_BORROWER'].includes(role.role))) {
        return UserType.APPLICANT;
    } else if (loan?.loanRoles.some(role => role?.user.id === id && [
        'LEAD_LENDER',
        'LENDER',
        'ASSOCIATE_LENDER',
        'UNDERWRITER_LENDER',
        'MANAGER_LENDER'].includes(role.role))) {
        return UserType.LENDER;
    }

    return UserType.ANONYMOUS;
}