import { getFileExtension } from './get-file-extension';
import { getMergableFile } from './get-mergable-file';
import { mergeImagesAsPdf } from './merge-images-as-pdf';
import { promiseWithAbort } from './promise-with-abort';

const MergeImages = ['JPG', 'JPEG', 'HEIC', 'PNG', 'TIFF', 'BMP', 'GIF', 'WEBP', 'DOC', 'DOCX'];

const MergeExtensions = ['PDF', ...MergeImages];

export const mergeFiles = async (files: File[], pdftronKey: string, options?: { signal?: AbortSignal }): Promise<File | null> => {
    // if we only have one file and it's not on the list of mergeable files, return it
    if (files.length === 1 && !MergeExtensions.includes(getFileExtension(files[0]).toUpperCase())) {
        return files[0];
    }
    try {
        const processedFiles = await promiseWithAbort(Promise.all(files.map(file => getMergableFile(file, pdftronKey))), options?.signal);
        const fileObject = await promiseWithAbort(mergeImagesAsPdf(processedFiles), options?.signal);
        return fileObject;
    } catch (error) {
        return null;
    }
}
