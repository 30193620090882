
import { getFileNameWithoutExtension } from './file/get-file-name-without-extension';
import { getFileExtension } from './get-file-extension';
// image extensions that we can embed into the pdf
const imageExtensions = ['jpg', 'jpeg', 'png'];


export async function mergeImagesAsPdf(files: File[]): Promise<File> {
    // dynamically load pdf-lib
    const PdfLib = await import('@cantoo/pdf-lib');

    const newPdfDoc = await PdfLib.PDFDocument.create();

    for (const file of files) {
        const extension = getFileExtension(file);
        const isFileAPdf = extension === 'pdf';
        const isImage = imageExtensions.includes(extension);
        // if not an image or pdf, skip
        if (!isImage && !isFileAPdf) {
            continue;
        }
        const fileBytes = await file.arrayBuffer();

        // if file is an image, embed it as an image
        if (isImage) {
            let image: ReturnType<typeof newPdfDoc.embedJpg['prototype']>;
            // if jpg, embed as jpg, otherwise embed as png
            if (['jpg', 'jpeg'].includes(extension)) {
                image = await newPdfDoc.embedJpg(fileBytes);
            } else {
                image = await newPdfDoc.embedPng(fileBytes);
            }
            const page = newPdfDoc.addPage();
            const { width, height } = page.getSize();
            const scaled = image.scaleToFit(width, height)
            const xPos = (width - scaled.width) / 2;
            const yPos = (height - scaled.height) / 2;
            page.drawImage(image, {
                x: xPos,
                y: yPos,
                width: scaled.width,
                height: scaled.height,
            });

        } else {
            const loadedPdf = await PdfLib.PDFDocument.load(fileBytes);
            const copiedPages = await newPdfDoc.copyPages(loadedPdf, loadedPdf.getPageIndices());
            copiedPages.forEach((page) => {
                // const annots = page.node.Annots()
                // annots.asArray().forEach((annotRef: PDFRef, index) => {
                // uncomment this if you want to set unique ids for annotations
                // keep in mind this will break any links between annotations
                // eg: a field displaying sum of two other fields
                // const annot = annots.lookup(index, PdfLib.PDFDict);
                // Generate a new unique ID
                // const newUniqueId = `Annotation-${Date.now()}-${Math.random() * 1000000}`;
                // annot.set(PdfLib.PDFName.of('P'), page.ref);
                // annot.set(PdfLib.PDFName.of('T'), PdfLib.PDFString.of(newUniqueId));
                // page.node.removeAnnot(annotRef);
                // page.node.addAnnot(annotRef);
                // })
                newPdfDoc.addPage(page);
            });
        }

    }

    const pdfBytes = await newPdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const fileName = `${getFileNameWithoutExtension(files[0].name)}.pdf`;
    const fileObject = new File([blob], fileName, { type: 'application/pdf' });
    return fileObject;
}
