import * as React from "react"

export const MenuIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={9}
        fill="none"
        viewBox="0 0 17 9"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.25}
            d="M1 1.582h15M1 7.414h15"
        />
    </svg>
)
