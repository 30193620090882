import * as React from "react"

export const TwoArrowsDownIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            fill="currentColor"
            d="m15.812 9.155-5.5 5.5-.281.28L5 9.906l-.813-.813c-.625-.625-1.656.344-1 1l5.531 5.531.813.813c.281.281.75.281 1 0l5.5-5.5.781-.781c.656-.625-.375-1.625-1-1Z"
        />
        <path
            fill="currentColor"
            d="M8.75 10.873c.28.282.53.532.812.813.281.281.75.281 1 0l5.5-5.5.781-.781c.625-.625-.343-1.657-1-1-1.875 1.812-3.687 3.656-5.53 5.468l-.282.282L5 5.123c-.282-.28-.532-.53-.813-.812-.625-.625-1.656.344-1 1 1.844 1.875 3.719 3.687 5.563 5.562Z"
        />
    </svg>
)
