import type { FormElementV2ResponseDto, JobElementDto } from "src/backend";

import { getChildrenIds } from "../form-elements";

export const getSelectedElementsDetails = (args: {
    selectedElements: FormElementV2ResponseDto[],
    allPackageElements: FormElementV2ResponseDto[],
}) => {
    const loanFormElementsMap = args.allPackageElements.reduce((acc, formElement) => {
        acc[formElement.id] = formElement;
        return acc;
    }, {} as Record<string, FormElementV2ResponseDto>);
    const allFormElementsIds: string[] = []
    // loop throw all selected form elements
    // and get their children ids recursively
    args.selectedElements.forEach((formElement) => {
        const allChildrenIds = getChildrenIds(formElement, args.allPackageElements, true);
        allFormElementsIds.push(formElement.id);
        allFormElementsIds.push(...allChildrenIds);
    })
    // filter out duplicates
    const uniqueElementsIds = [...new Set<string>(allFormElementsIds)];
    // count of form elements with answer
    const totalNotAnswered = uniqueElementsIds.filter((id) => loanFormElementsMap[id].storageType === 'FILE' &&
        !loanFormElementsMap[id].answer).length;
    // get virtual folder form elements
    const virtualFolderFormElementIds = uniqueElementsIds.filter((id) => {
        const formElement = loanFormElementsMap[id];
        return formElement.storageType === 'FOLDER' &&
            formElement.knowledgeBase === 'VIRTUAL_FOLDER';
    })
    // list of virtual folder form elements
    const virtualFolderFormElements = virtualFolderFormElementIds.map((id) => loanFormElementsMap[id]);
    // list of virtual folder form elements names 
    const virtualElementNames = virtualFolderFormElements.map(({ title }) => title);
    // list of non virtual folder form elements
    const nonVirtualElementIds = uniqueElementsIds.filter((id) => !virtualFolderFormElementIds.includes(id))

    const elementList: JobElementDto[] = args.allPackageElements
        .filter(({ id, answer }) => !!answer && uniqueElementsIds.includes(id)).map((element) => ({
            answerId: element.answer?.id,
            infoId: element.id,
            parentInfoId: element.parentId,
            storageType: element.storageType,
            title: element.title,
            virtual: element.knowledgeBase === 'VIRTUAL_FOLDER',
        }));

    // add the parent of each element to the list
    // if the parent is not already in the list
    elementList.forEach((element) => {
        const parentElement = loanFormElementsMap[element.parentInfoId];
        if (parentElement && !elementList.some(({ infoId }) => infoId === parentElement.id)) {
            elementList.push({
                answerId: parentElement.answer?.id,
                infoId: parentElement.id,
                parentInfoId: null,
                storageType: parentElement.storageType,
                title: parentElement.title,
                virtual: parentElement.knowledgeBase === 'VIRTUAL_FOLDER',
            });
        }
    });

    return {
        elementList,
        totalNotAnswered,
        uniqueElementsIds,
        virtualElementNames,
        nonVirtualElementIds,
    }
}
