import { Role } from "src/backend";
import { useGetLoggedInUserQuery } from "src/services/userApi";
import { Loan } from "src/types/loan";

interface UserProps {
    loan?: Loan;
}

export const useUser = (props: UserProps = {}) => {
    const { data: loggedInUser } = useGetLoggedInUserQuery();
    const loanRole = props.loan?.loanRoles.find((role) => role.user.id === loggedInUser.user.id);

    let userRole: Role = null;

    if (typeof loanRole !== 'undefined') {
        userRole = loanRole.role;
    } else if (loggedInUser?.user?.loggedCompanyRole) {
        userRole = loggedInUser.user.loggedCompanyRole;
    } else if (loggedInUser?.user?.loggedRoleGroup === 'LENDER') {
        userRole = "LENDER";
    } else if (loggedInUser?.user?.loggedRoleGroup === 'BORROWER') {
        userRole = "BORROWER";
    } else if (loggedInUser?.user?.loggedRoleGroup === 'CONTACT') {
        userRole = "CONTACT";
    }
    return {
        info: loggedInUser?.info,
        user: loggedInUser?.user,
        company: loggedInUser?.employer,
        loanRole,
        lenders: loggedInUser?.lenders,
        viewType: loggedInUser?.viewType,
        features: loggedInUser?.user?.features ?? [],
        userRole,
        onboardingNeeded: loggedInUser?.needOnboard,
        isBorrower: ['CONTACT', 'PRINCIPAL'].includes(loggedInUser?.viewType),
        isLender: loggedInUser?.viewType === 'LENDER',
        isUnderwriter: userRole === "UNDERWRITER_LENDER",
    } as const;
};