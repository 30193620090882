export const ZipFileAction = {
    EXTRACT: 'EXTRACT',
    IGNORE: 'IGNORE',
    ASK: 'ASK',
} as const;

export const ZipStatus = {
    ENCRYPTED: 'ENCRYPTED',
    ERRORED: 'ERRORED',
    SUCCESS: 'SUCCESS',
} as const;

export const SKIP_SIGNATURE_NAME_FOR_TASK = 'mysherpas';

export const MAX_DOLLAR_AMOUNT = 999_999_999_999;
