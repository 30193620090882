import Avatar, { type AvatarProps } from 'components/dist/atoms/Avatar';
import { forwardRef } from 'react';
import { Role } from "src/backend";
import { RoleType } from "src/constants/loan";
import { UserType } from "src/constants/user"
import { useUserType } from "src/hooks/use-user-type";
import { useGetDocumentWithDownloadUrlQuery } from 'src/services/documentApi';

interface Props {
    userId?: string
    avatarDocId?: string
    role?: Role
    size?: AvatarProps['size']
    onClick?: () => void
    className?: string
}

export const UserAvatar = forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props, ref) => {
    const { userId, role } = props;
    const { currentData: avatarDocumentData } = useGetDocumentWithDownloadUrlQuery({
        id: props.avatarDocId
    }, {
        skip: !props.avatarDocId
    })

    const userType = useUserType(userId);

    let className = "bg-blue-100 text-white";
    if (["BORROWER", "LEAD_BORROWER"].includes(props.role)) {
        className = "bg-yellow-60 text-black-primary";
    } else if (["CONTACT"].includes(props.role)) {
        className = "bg-yellow-76 text-black-primary";
    } else if (userType === UserType.ANONYMOUS && !role) {
        className = "bg-black-10 text-black-primary";
    } else if (!userId) {
        className = "bg-black-10 text-black-primary";
    }
    return <Avatar
        ref={ref}
        className={props.className}
        size={props.size}
        onClick={props.onClick}>
        <Avatar.Image src={avatarDocumentData?.downloadUrl.toString()} alt="user avatar" />
        <Avatar.Fallback className={className}>
            {props.children}
        </Avatar.Fallback>
    </Avatar>
})

UserAvatar.displayName = 'UserAvatar';