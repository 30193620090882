
import { grayscale, MarvinImage } from 'src/lib/marvinj';

export const useImageProcessing = () => {

    const getBlackAndWhiteImageDataURI = async (imageUrl: string): Promise<string> => {
        const { width, height } = await new Promise<{ width: number, height: number }>((resolve, reject) => {
            const image = new Image();
            image.src = imageUrl;
            image.onload = () => resolve({ width: image.width, height: image.height });
            image.onerror = () => reject(new Error('Image load failed'));
        });

        const imageDataURI: string = await new Promise((resolve, reject) => {
            try {
                const originalImage = new MarvinImage(width, height);
                const thresholdingImage = new MarvinImage(originalImage.getWidth(), originalImage.getHeight());

                originalImage.load(imageUrl, () => {
                    grayscale(originalImage, thresholdingImage);

                    const a = new FileReader();
                    a.onload = (e) => { resolve(String(e.target.result)); }
                    a.onerror = (e) => { reject(e); }
                    a.readAsDataURL(thresholdingImage.toBlob());
                });
            } catch (error) {
                console.error({ error });
                reject(error);
            }
        });
        // result blob to data uri
        return imageDataURI;
    }

    return { getBlackAndWhiteImageDataURI };
}


