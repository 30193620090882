import Button from "components/dist/atoms/Button"
import Dialog from "components/dist/atoms/Dialog"
import Icon from "components/dist/atoms/Icon"
import Input from "components/dist/atoms/Input"
import Label from "components/dist/atoms/Label"
import Text from "components/dist/atoms/Text"
import { VerificationCodeForm } from "src/components/auth/verification-code-form"
import { FormElementV2ResponseDtoExtended } from "src/types/formelement"
import { classNames } from "src/utils/tailwind-class-names"

import { useAddMicrosoftAccountDialogState } from "./add-microsoft-account-dialog.state"

export interface AddMicrosoftAccountDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onDownload: (formElement: FormElementV2ResponseDtoExtended) => Promise<void>;
    loading: boolean;
    formElement: FormElementV2ResponseDtoExtended;
}

export const AddMicrosoftAccountDialog: React.FC<AddMicrosoftAccountDialogProps> = (props) => {
    const state = useAddMicrosoftAccountDialogState(props);

    return <Dialog
        open={props.open}
        onOpenChange={props.onOpenChange}
    >
        <Dialog.Content
            aria-label="Add Microsoft Account Dialog"
            className={classNames("pt-8 pb-4 px-1 max-w-[95%] sm:max-w-96 rounded relative", {
                "sm:px-3": state.activeView === 'email',
                "sm:px-0": state.activeView === 'verification',
            })}>
            <Icon name="BigExclamationWarning" className='text-black-10 mx-auto' />
            {state.activeView === 'email' && <form onSubmit={state.handleSubmit(state.onSubmit)}>
                <Text size='sm' center as='div'>
                    You don’t have a Microsoft account associated with your email. Enter a Microsoft associated email below or download the item to edit and re-upload.
                </Text>
                <div className="px-1 mt-6 flex flex-col gap-2 h-20">
                    <Input
                        {...state.register('emailAddress')}
                        placeholder="Enter Microsoft Email Address" />
                    {state.errors.emailAddress && <Label variant="destructive" className="flex items-center gap-2 text-xs">
                        <Icon name="InfoEmpty" width={16} height={16} strokeWidth={1.5} />
                        {state.errors.emailAddress?.message}
                    </Label>}
                </div>
                <Dialog.Footer className="flex-row border-0 mt-6">
                    <Button
                        onClick={() => props.onOpenChange(false)}
                        variant='outline'>Cancel</Button>
                    <div className="flex-1 flex items-center justify-end gap-3">
                        <Button
                            onClick={() => props.onDownload(props.formElement)}
                            variant='outline' className="text-blue-100 gap-2">
                            <Icon name="DownloadMenu" width={16} height={16} />
                            Download
                        </Button>
                        <Button
                            loading={state.isLoading}
                            type='submit'>
                            Save Email
                        </Button>
                    </div>
                </Dialog.Footer>
            </form>}
            {state.activeView === 'verification' && <VerificationCodeForm
                disableAuth
                onVerify={() => state.onVerify(state.values['verificationCode'])}
                onChange={state.onCodeChange}
                onResend={state.onResendConfirmationCode}
                onChangeEmail={state.onChangeEmail}
                verifying={state.isLoading}
                countDown={state.verificationCodeCountDown}
                username={state.values['emailAddress']}
                error={state.errors.verificationCode?.message}
                value={state.values['verificationCode']}
            />}
        </Dialog.Content>
    </Dialog>
}