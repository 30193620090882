import * as React from "react"

export const EditPencilIcon = (props) => (
    <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.85 4.857 13.21 2.5l4.123 4.125-2.357 2.357M10.85 4.857l-8.005 8.005a.833.833 0 0 0-.244.59v3.78h3.78a.834.834 0 0 0 .589-.244l8.005-8.006M10.85 4.857l4.125 4.125"
            stroke="currentcolor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
