import * as React from "react"

export const NotAllowedIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M15.952 4.168a8.308 8.308 0 0 0-5.95-2.5A8.333 8.333 0 0 0 4.05 15.835m0 0a8.308 8.308 0 0 0 5.951 2.5 8.333 8.333 0 0 0 5.95-14.167l-11.9 11.667Z"
        />
    </svg>
)
