import { forwardRef } from 'react';
import InputMask from 'react-input-mask';

// eslint-disable-next-line react/display-name
export const MaskZipCodeInput = forwardRef((props, ref) => {
    const { ...other } = props;

    return (
        <InputMask
            {...other}
            inputRef={ref}
            mask="99999t9999"
            maskChar={null}
            formatChars={{ "9": "[0-9]", "t": "[\-]" }}
        />
    );
});