export function promiseWithAbort<T>(promise: Promise<T>, signal?: AbortSignal): Promise<T> {
    return new Promise((resolve, reject) => {
        if (signal) {
            // If the signal is already aborted, reject the promise immediately
            if (signal.aborted) {
                reject(new DOMException('Aborted', 'AbortError'));
                return;
            }

            // Set up an event listener for when the signal is aborted
            const onAbort = () => {
                reject(new DOMException('Aborted', 'AbortError'));
            };

            signal.addEventListener('abort', onAbort);

            // Resolve or reject the original promise
            promise.then(
                (value) => {
                    signal.removeEventListener('abort', onAbort);
                    resolve(value);
                },
                (error) => {
                    signal.removeEventListener('abort', onAbort);
                    reject(error);
                }
            );
        } else {
            // If no signal is provided, just resolve or reject the original promise
            promise.then(resolve, reject);
        }
    });
}
